/* /var/www/website2024/192.testproject.work/website2024/S-307-napharun-getdat/myapp/src/style/Webboard.scss  */
/* Webboard.module.scss */

.rootindex1 {
  background-color: #003543;
  padding: 5%;
}

.ecoStyleCard {
  background-color: #f0f2f2;
  border: 1px solid #82b74b;
  border-radius: 6px;
  padding: 8px;
  margin-bottom: 8px;
}

.ecoStyleCard .cardTitle {
  font-size: 0.9rem;
  color: #013544;
}

.ecoStyleCard .cardText {
  font-size: 0.8rem;
  color: #013544;
}

/* Compact buttons */
.toggleCommentButton,
.submitButton {
  background-color: #003543;
  color: #ffffff;
  font-size: 0.8rem;
  padding: 6px 10px;
  border-radius: 4px;
}

.toggleCommentButton:hover,
.submitButton:hover {
  background-color: #002d3e;
}

/* Compact form controls */
.formControl {
  font-size: 0.85rem;
  padding: 6px;
  border: 1px solid #82b74b;
  border-radius: 4px;
}


.additionalReviewInfo:hover {
  color: #f4fcfd;
  background-color: #06535d;
  border-color: #004851;
}

/* Additional review info */
.additionalReviewInfo {
  background-color: #004851;
  color: #ffffff;
  padding: 6px;
  border: 1px solid #82b74b;
  border-radius: 6px;
}
