/* /var/www/website2024/192.testproject.work/website2024/S-307-napharun-getdat/myapp/src/style/App.scss */

/* Flexbox layout to ensure equal card heights */

// Inside App.scss or similar file
// Ensure all cards have the same height
.card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
  
  // Ensure footer buttons are aligned consistently
  .p-buttonset {
    display: flex;
    justify-content: space-between;
    margin-top: auto;  // Push buttons to the bottom
  }
  

.p-card-body {
    flex-grow: 1;
}



.card-container {
    display: flex;
    flex-direction: column;
    height: 100%;
}

// SCSS for customizing Link appearance
.p-button.p-component {
    color: #f6f6f6;
    text-decoration: none;
    
    &:hover {
      text-decoration: underline;
    }
  }

  
.card-content {
    flex-grow: 1;
    max-height: 00px;
    overflow: hidden;
}

.card-footer {
    display: flex;
    justify-content: space-between;
    margin-top: auto;
}

.card-text {
    height: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.card-image {
    max-height: 150px;
    object-fit: cover;
}

.button-set {
    display: flex;
    justify-content: space-between;
}

.pagination-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
}